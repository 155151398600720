<template>
  <div>
    <UniversalSelectField
        :modelValue="modelValue.docStatus || defaultDocumentStatus"
        :displayProp="'displayName'"
        :options="documentStatusRefs"
        @update:modelValue="update('docStatus', $event)"
        :key-prop="'id'"
        data-qa="status"
    >
      <label class="label label--required">Статус действия</label>
    </UniversalSelectField>

    <TextField
        title="Наименование документа"
        :modelValue="modelValue.docName"
        @update:modelValue="update('docName', $event, v$.docName)"
        :errors="v$.docName.$errors"
        data-qa="name"
    >
      <label class="label" for="docName">Наименование документа</label>
    </TextField>

    <TextField
        :modelValue="modelValue.docNum"
        @update:modelValue="update('docNum', $event, v$.forNum)"
        :errors="v$.$error ? v$.forNum.$errors : []"
        data-qa="number"
    >
      <label :class="modelValue.isChangeForProlongation ? 'label' : 'label label--required'" for="docNum">Номер документа</label>
    </TextField>

    <DateField
        :modelValue="modelValue.docDate"
        @update:modelValue="update('docDate', $event, v$.docDate)"
        :errors="v$.docDate.$errors"
        :max="maxDate"
        data-qa="documentDate"
    >
      <label class="label label--required">Дата документа</label>
    </DateField>
    <TextField
        :modelValue="modelValue.description"
        @update:modelValue="update('description', $event)"
        data-qa="changeDescription"
    >
      <label class="label" for="description">Описание изменений</label>
    </TextField>

    <SubjectField
        :modelValue="modelValue.orgName"
        @update:modelValue="update('orgName', $event, v$.orgName)"
        mode="Organization"
        data-qa="providingOrganization"
        :errors="v$.orgName.$errors"
    >
      <template #label
      ><label
          class="label"
          for="registration-request-person-who-sent"
      >
        Наименование органа, утвердившего документ
      </label></template
      >
    </SubjectField>

    <slot name="additional-fields"></slot>

    <TextField
        :modelValue="modelValue.note"
        @update:modelValue="update('note', $event)"
        data-qa="note"
    >
      <label class="label" for="note">Примечание</label>
    </TextField>

    <UniversalSelectField
      :options="stprfNames"
      :modelValue="modelValue.stprfName"
      @update:modelValue="update('stprfName', $event, v$.stprfName)"
      :isMultiSelect="true"
      :errors="v$.stprfName.$errors"
      data-qa="schemaName"
    >
      <label class="label label--required">Наименование схемы</label>
    </UniversalSelectField>

    <MultiSelectField
        :modelValue="modelValue.documentsOriginRegistration"
        @update:modelValue="update('documentsOriginRegistration', $event, v$.documentsOriginRegistration)"
        :options="docs"
        @reload="fetchWithDelay"
        :errors="v$.documentsOriginRegistration.$errors"
        :itemValidationRule="itemValidationRule"
        data-qa="documentCoveredChange"
        keyProp="registrationNumber"
    >
      <label class="label label--required"
      >Документ, на который распространяется изменение</label
      >
    </MultiSelectField>
    <slot name="additional-fields-bottom"></slot>
  </div>
</template>

<script>
import {computed, toRefs, watch, ref} from "vue";
import {useRegistrationProcessStatusRefs} from "@/hooks/registrationProcessRefs";
import useValidator from "./validator";
import {helpers, required, minValue, maxLength, maxValue} from "@vuelidate/validators";
import DocIzSTPRF from "../../../models/documents/docIzSTPRF";
import useDocRefSTPRFNameDirectoryRef from "@/components/RegistrationProcess/RegistrationDocumentFields/hooks/docRefSTPRFNameHook";
import TextField from "../../basic/form/TextField.vue";
import DateField from "../../basic/form/DateField.vue";
import SubjectField from "../../basic/form/SubjectField.vue";
import MultiSelectField from "../../basic/MultiSelectField.vue";
import useDocIzOrigin from "./hooks/docIzOrigin";
import subjectValidators from '../../../models/subjectValidators';
import UniversalSelectField from "@/components/basic/form/select/UniversalSelectField.vue";

export default {
  components: {
    UniversalSelectField,
    TextField,
    DateField,
    SubjectField,
    MultiSelectField
  },
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Object,
      required: true,
      default: () => new DocIzSTPRF(),
    },
    documentTypeId: {
      type: Number,
      required: true,
    },
    validationRules: {
      type: Object,
      default: () => {
        return {
          docDate: {
            minValue: helpers.withMessage(
                "Дата должна быть позднее 01.01.1970",
                minValue(new Date(1970, 0, 1))
            ),
            maxValue: helpers.withMessage("Дата документа не должна быть позднее сегодняшнего дня", maxValue(new Date(new Date().setHours(23, 59, 59)))),
            required: helpers.withMessage("Заполните поле", required),
          },
          docName: {
            maxLength: helpers.withMessage(
                "Количество символов не должно превышать 1000",
                maxLength(1000)
            ),
          },
          note: {
            maxLength: helpers.withMessage(
                "Количество символов не должно превышать 4000",
                maxLength(4000)
            ),
          },
          stprfName: {
            required: helpers.withMessage("Заполните поле", required),
          },
          documentsOriginRegistration: {
            required: helpers.withMessage("Заполните поле", required),
          },
          orgName: {...subjectValidators}
        };
      },
    },
  },
  setup(props, {emit}) {
    const { value: stprfNames } = useDocRefSTPRFNameDirectoryRef();
    const {documentStatusRefs} = useRegistrationProcessStatusRefs();
    const defaultDocumentStatus = computed(() => {
      return documentStatusRefs.value.find((s) => s.id == 1);
    });

    const {docs, fetchWithDelay} = useDocIzOrigin(props.documentTypeId);
    const {modelValue: data, validationRules: rules} = toRefs(props);

    const ruleForNum = (param) => (value) => {

      param.value = [];
      if (value.isChangeForProlongation) {
        return true;
      }
      let result = helpers.req(value.docNum);
      if (!result) {
        param.value.push({$message: "Заполните поле"});
      }

      if (value.docNum && value.docNum.length > 70) {
        result = false;
        param.value.push({$message: "Количество символов не должно превышать 70"});
      }

      return result;
    };
    const errorNum = ref([]);

    let itemValidationRule = {

      registrationNumber: helpers.withMessage(
          "Заполните или удалите неиспользуемое поле",
          (value) => {
            return value !== null && value["registrationNumber"] !== undefined
          }
      ),
    };

    rules.value.forNum = helpers.withMessage(
        "Заполните поле",
        ruleForNum(errorNum)
    );

    const currentDate = new Date();
    const maxDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`

    const v$ = useValidator(data, rules.value);
    v$.value.forNum.$errors = errorNum;

    watch(
        () => defaultDocumentStatus.value,
        () => {
          if (
              !props.modelValue.docStatus &&
              defaultDocumentStatus.value !== undefined
          ) {
            update("docStatus", defaultDocumentStatus.value);
          }
        }
    );

    function update(fieldName, value, field) {
      const newValue = {...props.modelValue};
      newValue[fieldName] = value;
      if (field) field.$model = value;
      emit("update:modelValue", newValue);
    }

    return {
      //other
      documentStatusRefs,
      defaultDocumentStatus,
      update,
      v$,
      docs,
      fetchWithDelay,
      maxDate,
      itemValidationRule,
      stprfNames
    };
  },
};
</script>
<style scoped>
/* todo копипаста */
.add-subject__icon {
  margin-right: 5px;
}

.add-subject:hover .add-subject__icon path {
  stroke: var(--basic-black);
}

.add-subject {
  position: relative;
  padding-top: 4px;
  padding-bottom: 7px;
  box-sizing: border-box;
}

.add-subject::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: var(--basic-grey-light);
  bottom: -6px;
}
</style>